import axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';
import { getServiceUrl } from './helpers';


const URLS = {
	local: 'http://localhost:3000/api/v1',
	dev: 'https://node-dev-lakinlggia-uc.a.run.app/api/v1',
	staging: '',
	prod: 'https://node.drumnow.app/api/v1'
};


export class NodeServiceClass {
	axios: AxiosInstance;

	constructor() {
		this.axios = axios.create({
			baseURL: getServiceUrl(<string>process.env.NODE_ENV, URLS),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
    });
	}

	addAuthHeader(token: string) {
		this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
  
  removeAuthHeader() {
    this.axios.defaults.headers.common.Authorization = '';
  }

	signIn(email: string) {
		// sign in user using email and auth token
		return this.axios.post('/login/admin', {
			email
		});
  }

  getItem(model: string, _id: string) {
    return this.axios.get(`/${model}/${_id}`);
  }

  async getPack(_id: string) {
    const packData = await this.axios.get(`/pack/${_id}`);
    const loopsData = await this.axios.get(`/pack/${_id}/loops`);

    packData.data.loops = loopsData.data;

    return packData;
  }

  async getLibrary(_id: string) {
    const libraryData = await this.axios.get(`/library/${_id}`);
    
    const packs = await Promise.all(libraryData.data.packs.map(async (id) => {
      const response = await this.axios.get(`/pack/${id}`);
      return response.data;
    }));

    const loops = await Promise.all(
      libraryData.data.loops.map(async (loopId) => {
        const response = await this.axios.get(`/loop/${loopId}`);
        return response.data;
      })
    );

    const videos = await Promise.all(
      libraryData.data.videos.map(async (videoId) => {
        const response = await this.axios.get(`/video/${videoId}`);
        return response.data;
      })
    );

    libraryData.data.packs = packs;
    libraryData.data.loops = loops;
    libraryData.data.videos = [];

    return libraryData;
  }

  getItems(model: string) {
    return this.axios.get(`/${model}`);
  }
  
  getSchema(model: string) {
    return this.axios.get(`/schema/${model}`);
  }

  getRelationships(model: string) {
    return this.axios.get(`/relationships/${model}`);
  }

  deleteItem(model: string, _id: string) {
    return this.axios.delete(`/${model}/${_id}`);
  }

  createItem(model: string, item: any) {
    return this.axios.post(`/${model}`, item);
  }

  updateItem(model: string, item: any) {
    return this.axios.put(`/${model}/${item._id}`, item);
  }

  uploadItem(model: string, id: string, type: string, filename: string, data: any) {
    return this.axios.post(`/${model}/${id}/${type}/${filename}`, data);
  }
}

export const NodeService = new NodeServiceClass();
