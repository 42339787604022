
import { ArtistSchema } from '@/schemas/Artist.schema';
import { PackSchema } from '@/schemas/Pack.schema';
import { VideoSchema } from '@/schemas/Video.schema';
import { TagSchema } from '@/schemas/Tag.schema';

export default [
  {
    path: '/',
		name: 'dashboard',
		meta: {
			requiresAuth: true
		},
		component: () => import('@/views/Dashboard.vue'),
  },
  {
    path: '/data',
		name: 'data',
		meta: {
			requiresAuth: true
		},
    component: () => import('@/views/Data.vue')
  },
  {
		path: '/pack-upload-tool/:packId',
		name: 'pack-upload-tool',
		meta: {
			requiresAuth: true,
		},
		component: () => import('@/views/PackUploadTool.vue'),
		props: true,
	},
	{
		path: '/pack-upload-tool/new',
		name: 'pack-upload-tool-new',
		meta: {
			requiresAuth: true,
		},
		component: () => import('@/views/PackUploadTool.vue'),
  },
  {
    path: '/data/manage/:type',
    meta: {
      requiresAuth: true
    },
    props: true,
    component: () => import('@/views/Manage.vue')
  },
  {
    path: '/data/manage/:type/:id',
    name: 'edit-item',
    meta: {
      requiresAuth: true
    },
    props: true,
    component: () => import('@/views/Edit.vue')
  },
	{
    path: '/login',
		name: 'login',
		meta: {
			requiresAuth: false
		},
		component: () => import('@/views/Login.vue'),
	},
	{
		path: '/manage/packs',
		name: 'managePacks',
		meta: {
			requiresAuth: true
		},
		component: () => import('@/views/ManageCollection.vue'),
		props: {
			collection: 'packs',
			schema: PackSchema
		},
	},
	{
		path: '/manage/packs/new',
		name: 'createPack',
		meta: {
			requiresAuth: true
		},
		component: () => import('@/views/CreatePack.vue'),
	},
	{
		path: '/manage/artists',
		name: 'manageArtists',
		meta: {
			requiresAuth: true
		},
		component: () => import('@/views/ManageCollection.vue'),
		props: {
			collection: 'artists',
			schema: ArtistSchema
		}
	},
	{
		path: '/manage/videos',
		name: 'manageVideos',
		meta: {
			requiresAuth: true
		},
		component: () => import('@/views/ManageCollection.vue'),
		props: {
			collection: 'videos',
			schema: VideoSchema
		}
	},
	{
		path: '/manage/images',
		name: 'manageImages',
		meta: {
			requiresAuth: true
		},
		component: () => import('@/views/ManageCollection.vue'),
		props: {
			collection: 'images'
		}
	},
	{
		path: '/manage/subscriptions',
		name: 'manageSubscriptions',
		meta: {
			requiresAuth: true
		},
		component: () => import('@/views/ManageCollection.vue'),
		props: {
			collection: 'subscriptions'
		}
	},
	{
		path: '/manage/users',
		name: 'manageUsers',
		meta: {
			requiresAuth: true
		},
		component: () => import('@/views/ManageCollection.vue'),
		props: {
			collection: 'users'
		}
	},
	{
		path: '/manage/tags',
		name: 'manageTags',
		meta: {
			requiresAuth: true
		},
		component: () => import('@/views/ManageCollection.vue'),
		props: {
			collection: 'tags',
			schema: TagSchema
		}
	},
	{
		path: '/portal/content',
		name: 'managePortal',
		meta: {
			requiresAuth: true
		},
		component: () => import('@/views/PortalCms.vue')
	}
];
