import axios, { AxiosInstance } from 'axios';
import { StorageService } from '@/services/storage.service';

const URLS = {
	local: 'http://localhost:3000',
	dev: 'https://dn-swarm-dev.herokuapp.com',
	staging: '',
	prod: 'https://swarm.drumnow.app'
};

const TIMEOUT = 5000;

export class SwarmServiceClass {
	axios: AxiosInstance;

	constructor() {
		this.axios = axios.create({
			baseURL: URLS.prod,
			timeout: TIMEOUT,
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		});
	}

	addAuthHeader(token: string) {
		this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
	}

	getCollection(collection: string) {
		return this.axios.get(`/${collection}`)
			.then(data => (data && data.data) || [])
			.catch(SwarmServiceClass.handleError);
	}

	getItem(collection: string, id: string) {
		return this.axios
			.get(`/${collection}/${id}`)
			.catch(SwarmServiceClass.handleError);
	}

	createItem(collection: string, item: any) {
		return this.axios
			.post(`/${collection}`, item)
			.catch(SwarmServiceClass.handleError);
	}

	updateItem(collection: string, item: any, id: string) {
		return this.axios
			.put(`/${collection}/${id}`, item)
			.catch(SwarmServiceClass.handleError);
	}

	deleteItem(collection: string, id: string) {
		return this.axios
			.delete(`/${collection}/${id}`)
			.catch(SwarmServiceClass.handleError);
	}

	uploadFile(file: any, collection: string, type: string) {
		const reader = new FileReader();
		let url;
		const a = this.axios;
		reader.addEventListener('load', () => {
			url = reader.result;
		});
		
		reader.readAsDataURL(file);

		// return StorageService.upload(file);
		// return this.axios
		// 	.post(`/${collection}/${type}/upload`, url, {
		// 		headers: {
		// 			'Content-Type': 'multipart/form-data'
		// 		}
		// 	})
		// 	.catch(SwarmServiceClass.handleError);
	}

	static handleError(error: Error): void {
		// console.error('SwarmService Error:', error);
	}
}

export const SwarmService = new SwarmServiceClass();
