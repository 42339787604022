






















import Vue from 'vue';
import { mapState } from 'vuex';

import Sidebar from './components/Sidebar.vue';
import Toolbar from './components/Toolbar.vue';

// import { SwarmService } from '@/services/swarm.service';

export default Vue.extend({
  name: 'App',

  components: {
    AppSidebar: Sidebar,
    AppToolbar: Toolbar,
  },

  data: () => ({
    //
	}),

	methods: {
		handleCreate(collection: string) {
			if (collection === 'packs') {
				this.$router.push('/manage/packs/new');
			}
		}
	},

	computed: {
		...mapState([
			'auth'
		]),
    showToolbar() {
      return this.$route.name !== 'login';
    }
	},
	
	mounted() {

	}
});
