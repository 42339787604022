import { cloneDeep } from 'lodash';
import { NodeService } from '../../../services/node.service';
import ActionService from '../../action.service';


interface ManageState {
  list: [];
  schema: {};
  relationships: {};
}

function reduceSchema(item) {
  // Reduce Request body
  const oItem = item;
  const rItem = item;

  const keys = Object.keys(oItem);
  for (const key of keys) {
    if (key !== 'downloads') {
      if (Array.isArray(oItem[key])) {
        const oArray = oItem[key];
        const rArray = oArray.map(obj => obj._id);
        rItem[key] = rArray;
      } else if (typeof oItem[key] === 'object' && oItem[key]._id) {
        rItem[key] = oItem[key]._id;
      }
    }
  }

  return rItem;
}

export default {
  name: 'manage',
  state: {
    list: [],
    schema: {},
    relationships: {}
  },
  actions: {
    RETRIEVE_MANAGE_LIST({ commit }, payload) {
      return ActionService(async () => {
        const response = await NodeService.getItems(payload.model) || {};
  
        if (response && response.data) {
          commit('SET_MANAGE_LIST', response.data);
        }
      });
    },
    RETRIEVE_MANAGE_SCHEMA({ commit }, payload) {
      return ActionService(async () => {
        const response = await NodeService.getSchema(payload.model) || {};
  
        if (response && response.data) {
          commit('SET_MANAGE_SCHEMA', response.data);
        }
      });
    },
    RETRIEVE_MANAGE_SCHEMA_RELATIONSHIPS({ commit }, payload) {
      return ActionService(async () => {
        const response = await NodeService.getRelationships(payload.model) || {};
  
        if (response && response.data) {
          commit('SET_MANAGE_SCHEMA_RELATIONSHIPS', response.data);
        }
      });
    },
    async CREATE_MANAGE_ITEM({ commit }, payload) {
      let { item } = payload;
      const { model } = payload;

      item = reduceSchema(item);
      const response = await NodeService.createItem(model, item);
      return response.data;
    },
    async SAVE_MANAGE_ITEM({ commit }, payload) {
      let { item } = payload;
      const { model } = payload;

      item = reduceSchema(item);
      const response = await NodeService.updateItem(model, item);
      return response.data;
    },
    async DELETE_MANAGE_ITEM({ commit }, payload) {
      const { _id, model } = payload;
      const response = await NodeService.deleteItem(model, _id);

      return response.data;
    },
    async DUPLICATE_MANAGE_ITEM({ commit }, payload) {
      const { model } = payload;
      const { item } = payload;

      
      let cloneItem = cloneDeep(item);
      
      cloneItem.name = `${cloneItem.name} Copy`;
      cloneItem = reduceSchema(cloneItem);
      delete cloneItem.__v;
      delete cloneItem._id;

      const response = await NodeService.createItem(model, cloneItem);

      return response.data;
    },
    CLEAR_MANAGE_STATE({ commit }) {
      commit('SET_MANAGE_LIST', []);
      commit('SET_MANAGE_SCHEMA', {});
      commit('SET_MANAGE_SCHEMA_RELATIONSHIPS', {});
    }
  },
  mutations: {
    SET_MANAGE_LIST(state: ManageState, payload) {
      state.list = payload;
    },
    SET_MANAGE_SCHEMA(state: ManageState, payload) {
      state.schema = payload;
    },
    SET_MANAGE_SCHEMA_RELATIONSHIPS(state: ManageState, payload) {
      state.relationships = payload;
    },
  }
};
