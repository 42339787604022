import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
		dark: true,
		themes: {
			dark: {
				primary: '#54aba3',
				secondary: '#4FB09C',
				accent: '#B04F63',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',
			}
		}
	},
	options: {
		customProperties: true
	}
});
