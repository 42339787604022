import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/plugins/auth';

import { NodeService } from '@/services/node.service';
import { SwarmService } from '@/services/swarm.service';

import Manage from './modules/manage';
import Edit from './modules/edit';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
		auth: {
			profile: {},
			credential: '',
			authenticated: false
		}
  },
  mutations: {
		setAuth(state, payload) {
			state.auth = payload;
		}
  },
  actions: {
		async login({ commit }, { email, password }) {
			try {
				const userAuth = await auth.signInWithEmailAndPassword(email, password);
				if (userAuth && userAuth.user) {
					const token = await userAuth.user.getIdToken();
	
					if (!token) { 
            return { success: false };
          }

          NodeService.addAuthHeader(token);
          SwarmService.addAuthHeader(token);
	
          const userData = await NodeService.signIn(email);
          
          if (userData.data.error) {
            NodeService.removeAuthHeader();
            return { success: false };
          }

					const payload = {
						profile: userData.data,
						credential: token,
						authenticated: true
					};

					localStorage.setItem('dn_fortress_user', JSON.stringify(payload));
					commit('setAuth', payload);
					return { success: true, payload };
				}

				return { success: false };
			} catch (error) {
				return { success: false };
			}
		}
  },
  modules: {
    manage: Manage,
    edit: Edit
  },
});
