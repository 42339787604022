export const getServiceUrl = (env: string, urls: any) => {
	let url = '';

	switch (env) {
		case 'development':
			url = urls.dev;
			break;
		case 'staging':
		case 'test':
			url = urls.staging;
			break;
		case 'production':
		default:
			url = urls.prod;
			break;
	}

	return url;
};
