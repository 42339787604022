import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
	const { authenticated } = store.state.auth;

	if (to.matched.some(record => record.meta.requiresAuth) && !authenticated) {
		next('/login');
	} else {
		next();
	}
});

export default router;
