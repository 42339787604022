import { Pack } from '@/interfaces/Pack.interface';

export const PackSchema: Pack = {
	name: '',
	subtitle: '',
	description: '',
	version: '',
	accent: '',
	thumbnailUrl: '',
	price: 0,
	coverUrl: '',
	previewUrl: '',
	addedOn: '',
	expiredOn: '',
	published: false,
	type: 'Factory',
	genre: '',
	kits: [],
	tempos: [],
	categories: [],
	artist: '',
	loops: [],
	tags: [],
};
