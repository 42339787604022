import { NodeService } from '../../../services/node.service';

interface EditState {
  item: {};
}

export default {
  state: {
    item: {}
  },
  actions: {
    async RETRIEVE_EDIT_ITEM({ commit }, payload) {
      const { _id, model } = payload;
      let response;

      if (model === 'pack') {
        response = await NodeService.getPack(_id);
      } else if (model === 'library') {
        response = await NodeService.getLibrary(_id);
      } else {
        response = await NodeService.getItem(model, _id);
      }

      commit('SET_EDIT_ITEM', response.data);
      return response.data;
    },
    async UPLOAD_EDIT_ITEM({ commit }, payload) {
      const {
        id, model, type, filename, file
      } = payload;

      const formData = new FormData();

      formData.append('file', file);

      const response = await NodeService.uploadItem(model, id, type, filename, formData);

      commit('SET_EDIT_ITEM', response.data);
      return response.data;
    },
    CLEAR_EDIT_STATE({ commit }) {
      commit('CLEAR_EDIT_ITEM');
    }
  },
  mutations: {
    SET_EDIT_ITEM(state: EditState, payload) {
      state.item = payload;
    },
    CLEAR_EDIT_ITEM(state: EditState) {
      state.item = {};
    }
  }
};
