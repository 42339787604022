import { Video } from '@/interfaces/Video.interface';

export const VideoSchema: Video = {
	name: '',
	title: '',
	subtitle: '',
	thumbnailUrl: '',
	description: '',
	vimeoId: '',
	artistId: '',
	exclusive: true,
	tags: [],
	relatedPacks: [],
	relatedVideos: [],
	artist: '',
};
