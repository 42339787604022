export default function (method: any) {
  try {
    method();
  } catch (error) {
    let trys = 0;
    try {
      if (trys < 5) {
        method();
      }
    } catch (err) {
      trys += 1;
      console.log(err);
    }
    throw error;
  }
}
