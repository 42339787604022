import firebase from 'firebase';
import 'firebase/auth';

const firebaseConfig = {
	apiKey: 'AIzaSyCXDvKIUe6kL4jR39hvEeEuJ2S2FXrfGWQ',
	authDomain: 'drumnow-apps.firebaseapp.com',
	databaseURL: 'https://drumnow-apps.firebaseio.com',
	projectId: 'drumnow-apps',
	storageBucket: 'drumnow-apps.appspot.com',
	messagingSenderId: '289335709014',
	appId: '1:289335709014:web:f156493a67fe30958d5af7'
};

firebase.initializeApp(firebaseConfig);

export default firebase.auth();
